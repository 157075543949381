<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="py-3 text-center">
          <h1 class="h3">{{ $t('message.support') }}</h1>
          <p class="lead">
            {{ $t('message.support_intro') }}
          </p>
        </div>
        <form @submit.prevent="submit">
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.telephone')"
                :v="$v.form.phone">
                <input class="form-control" id="phone"
                       type="text"
                       v-model="form.phone"
                       v-on:blur="$v.form.phone.$touch()"
                />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.subject')"
                :v="$v.form.subject">
                <input class="form-control" id="subject"
                       type="text"
                       v-model="form.subject"
                       v-on:blur="$v.form.subject.$touch()"
                />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.classification')"
                :v="$v.form.classification">
                <select class="form-control" v-model="form.classification"
                        v-on:blur="$v.form.classification.$touch()">
                  <option value="attivazioni_rinnovi">{{$t('message.attivazioni_rinnovi')}}</option>
                  <option value="diritto-recesso">{{$t('message.diritto_recesso')}}</option>
                  <option value="modifica-dati-amministrativi">{{$t('message.modifica_dati_amministrativi')}}</option>
                  <option value="pagamenti-fatturazioni">{{$t('message.pagamenti_fatturazioni')}}</option>
                  <option value="agevolazioni-iva">{{$t('message.agevolazioni_iva')}}</option>
                  <option value="altra-richiesta-commerciale">{{$t('message.altra_richiesta_commerciale')}}</option>
                  <option value="malfunzionamento">{{$t('message.malfunzionamento')}}</option>
                  <option value="assistenza-tecnica">{{$t('message.assistenza_tecnica')}}</option>
                </select>
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.priority')"
                :v="$v.form.classification">
                <select class="form-control" v-model="form.priority"
                        v-on:blur="$v.form.priority.$touch()">
                  <option value="urgente-bloccante">{{$t('message.urgente-bloccante')}}</option>
                  <option value="urgente-non-bloccante">{{$t('message.urgente-non-bloccante')}}</option>
                  <option value="non-urgente">{{$t('message.non-urgente')}}</option>
                  <option value="informazioni">{{$t('message.informazioni')}}</option>
                </select>
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.message')"
                :v="$v.form.message">
                  <textarea class="form-control" id="message"
                            type="textarea"
                            rows="15"
                            v-model="form.message"
                            v-on:blur="$v.form.message.$touch()"
                  />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              {{$t('message.disclaimer')}}
            </b-col>
          </b-row>
          <hr class="mb-4">
          <b-row>
            <b-col sm="12" md="6">
              <b-button
                :class="{invalid:$v.form.$invalid}"
                block
                style="margin-bottom: 2px !important;"
                type="submit" class="bg-theme-secondary mb-3">
                {{$t('message.button_send_text')}}
              </b-button>
            </b-col>
            <b-col sm="12" md="6">
              <b-button
                block type="button"
                v-on:click="reset()"
                variant="secondary">
                {{$t('message.button_reset_text')}}
              </b-button>
            </b-col>
          </b-row>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import ValidationField from "../../components/ui/ValidationField";
  import StarRating from "vue-star-rating";
  import Api from "../../components/common/Api";
  import ApiResolver from "../../components/common/ApiResolver";
  import {required,email} from "vuelidate/lib/validators";

  export default {
    name: "Support",
    components: {
      ValidationField,
    },
    data() {
      return {
        form: {
          message: null,
          subject: null,
          classification: null,
          priority: null
        },
        error_message: null
      }
    },
    computed: {},
    methods: {
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        Api
          .post(ApiResolver.get('SUPPORT'), JSON.stringify(this.form))
          .then(response => {
            this.$store.dispatch('alerts/setAlert', {
              'type': 'success',
              'text': this.$t('message.ticket_opened')
            });
            this.$router.push({name:'categories'})
          })
          .catch(reason => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: reason.title + ' : ' + reason.detail
            });
          });
      },
      reset() {
        this.form = {
          message: null,
          subject: null,
          phone: null,
          classification: null,
          priority: null
        };
        this.$v.form.reset();
      }
    },
    mounted() {
    },
    validations: {
      form: {
        phone : {},
        classification: {
          required: required
        },
        message: {
          required: required,
        },
        priority: {
          required: required
        },
        subject: {
          required: required
        }
      }
    },
  }
</script>

<style scoped>

</style>

<style type="text/css">
  .hasError label {
    color: red;
  }
  .error {
    color: red
  }
</style>
